import { graphql, useStaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmptyBindingElement } from 'typescript'
import LocaleContext from '../../contexts/LocaleContext'
import useOnClickOutside from '../../hooks/useOnClickOutside'

import phoneIconImg from './assets/images/phone.svg'

import './styles.scss'

interface ContactDropdownProps {
  children: React.ReactNode
  type: 'phone' | 'email' | 'contactForm' | 'callUrl' | 'signUpForm'
  formType?: 'Contacto a ventas' | 'Solicitud de cuenta'
  campaign?: string
  locationTag?: string
}

const ContactDropdown: React.FC<ContactDropdownProps> = ({children, type, formType, campaign, locationTag}) => {
  const ref = React.createRef<HTMLDivElement>()
  const [dropdownActive, setDropdown] = useState(false)
  const data = useStaticQuery(
    graphql`
      query {
        contactInfo: allContentfulContactInfo(
          sort: {fields: country___name, order: ASC}
        ) {
          edges {
            node {
              email
              contactForm
              callUrl
              phone
              node_locale
              country {
                name
                countryBall {
                  localFile {
                    publicURL
                  }
                }
                code
              }
            }
          }
        }
      }
    `
  )

  const getLink = (edge: any): string => {
    let link = ''
    switch (type) {
      case 'email':
        link = `mailto:${edge.node.email}`;
        break;
      case 'callUrl':
        link = edge.node.callUrl;
        break;
      case 'contactForm':
        if(formType)
          link = `${edge.node.contactForm}?tipo=${formType}`;
        else
          link = edge.node.contactForm;
        break;
      case 'signUpForm':
        if(formType)
          link = `${edge.node.contactForm}?tipo=${formType}`;
        else
          link = edge.node.contactForm;
        break;
      case 'phone':
        link = `tel:${edge.node.phone}`
        break;
    }

    if(campaign) {
      if(link.indexOf('?'))
        link = `${link}&campaign=${campaign}`
      else
        link = `${link}?campaign=${campaign}`
    }
    return link
  }

  const sendAnalyticsEvent = (countryName: string) => {
    const typeString =  type === 'phone' ? 'Llamada' : 'Formulario'

    if(typeof window !== 'undefined') {
      if(type === 'signUpForm' || type === 'contactForm') {
        window.gtag('event', `Clic - ${typeString} ${formType} - ${countryName}`, {
          'event_category': 'Outbound',
          'event_label': `${locationTag}`,
        })
      } else {
        window.gtag('event', `Clic - ${typeString} - ${countryName}`, {
          'event_category': 'Contacto Ventas',
          'event_label': `Contacto Ventas - ${typeString}`,
        })
      }
    }

    return true
  }

  useOnClickOutside(ref, () => setDropdown(false))

  return (
    <div className="contact-dropdown dropdown" ref={ref}>
      <span onClick={() => setDropdown(!dropdownActive)} className="contact-dropdown__action-wrapper">
        {children}
      </span>
      <div
        className={`
          dropdown-menu
          ${dropdownActive ? 'show' : ''}
        `}
      >
        <h6 className="dropdown-header">
          {
            type === 'phone' ?
              <FormattedMessage id="navigation.select_country_to_call"/>
            :
              <FormattedMessage id="buttons.select_country"/>
          }
        </h6>
        <LocaleContext.Consumer>
          {localeCode => {
            const contacts =
              data.contactInfo.edges
              .filter((edge: any) => edge.node.node_locale === localeCode)
            return <>
            {
              contacts.map((edge: any) =>
                <OutboundLink
                  key={edge.node.country.code}
                  className="dropdown-item text-primary"
                  href={getLink(edge)}
                  onClick={() => sendAnalyticsEvent(edge.node.country.name) && setDropdown(!dropdownActive)}
                >
                  <div className="d-flex align-items-center">
                    <img src={edge.node.country.countryBall.localFile.publicURL}height="20px" width="20px" className="mr-4"/>
                    <b>{edge.node.country.name}</b>
                    { type === 'phone' && <span className="ml-2 mr-4">{edge.node.phone}</span> }
                  </div>
                  { type === 'phone' &&  <img src={phoneIconImg} className="phone-icon"/> }
                </OutboundLink>
              )
            }
            </>
        }}
        </LocaleContext.Consumer>
      </div>
    </div>
  )
}

export default ContactDropdown
