import { Location } from '@reach/router';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LocaleContext from '../../contexts/LocaleContext';
import { defaultFormatValues } from '../../data/defaultFormat';
import locales, { Locale } from '../../data/locales';
import ContactDropdown from '../ContactDropdown/ContactDropdown';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import logoImg from './assets/images/check.svg';
import messageIcon from './assets/images/message.svg';
import pciImg from './assets/images/pci-compliance.svg';
import icontech from './assets/images/icontech.svg';
import awsLogo from './assets/images/AWS.png'
import awsLogoWhite from './assets/images/aws-white.png'
import facebookImg from './assets/images/social/facebook.svg';
import githubImg from './assets/images/social/github.svg';
import instagramImg from './assets/images/social/instagram.svg';
import linkedinImg from './assets/images/social/linkedin.svg';
import twitterImg from './assets/images/social/twitter.svg';
import './styles.scss';


interface FooterProps {
  theme: 'primary' | 'secondary' | 'gray' | 'dark';
  page?: string;
}

const Footer: React.FC<FooterProps> = ({ theme, page }) => {
  const [languageDropdownActive, setLanguageDropdown] = useState(false);

  const localeCode = useContext(LocaleContext);

  const sendAMessage = () => {
    if(typeof window !== 'undefined' && typeof window.Intercom !== 'undefined') {
      window.Intercom('show')
    }
  }

  const LanguageDropdown = () => {
    const selectedLocale = locales.filter(locale=> locale.code === localeCode)[0];

    const getLocalizedPath = (locale: Locale, path: string) => {
      if(path.includes(`/${selectedLocale.code}`)) {
        path = path.replace(`/${selectedLocale.code}`, '');
      }

      return locale.default ? path : `/${locale.code}${path}`;
    };

    return (
      <Location>
        {({ location }) => {
          return (
            <div className="dropdown mt-5">
              <span
                className="d-flex align-items-center language-selector"
                id="languageMenu"
                onClick={() => setLanguageDropdown(!languageDropdownActive)}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={languageDropdownActive}
              >
                <img src={messageIcon} height="14px" width="auto" alt="Language icon" className="mr-1" />{selectedLocale.name} ({selectedLocale.code.toUpperCase()})
              </span>
              <div
                className={`dropdown-menu  ${languageDropdownActive ? 'show' : ''}`}
              >
                {
                  locales.filter(locale=> locale.code !== localeCode).map(locale =>
                    <Link
                      to={getLocalizedPath(locale, location.pathname)}
                      key={locale.code}
                      className={`dropdown-item text-primary`}
                    >
                      {locale.name} ({locale.code.toUpperCase()})
                    </Link>
                  )
                }
              </div>
            </div>
          )
        }}
      </Location>
    )
  }

  return (
    <footer className={`footer py-layout-5 px-2 footer--${theme}`}>
      <div className="container">
        { 
          page !== 'mundial' && 
            <div className="row pb-4">
              <div className="col-12">
                <h3 className="contact">
                { page == "cardsolution" ?
                <FormattedMessage id="footer.contact"
                    values={{
                      ...defaultFormatValues,
                      writeUsAMessage: (
                        <a className="dropdown-link" href='#form'>
                          <FormattedMessage id="footer.message_contact"
                            values={defaultFormatValues}
                          />
                        </a>
                      ),
                      email: (
                        <ContactDropdown type="contactForm">
                          <a className="dropdown-link" href='#form'>
                            <FormattedMessage id="footer.email_contact"
                              values={defaultFormatValues}
                            />
                          </a>
                        </ContactDropdown>
                      ),
                    }}
                  /> 
                  : 
                  <FormattedMessage id="footer.contact"
                      values={{
                        ...defaultFormatValues,
                        writeUsAMessage: (
                          <span className="dropdown-link" onClick={sendAMessage}>
                            <FormattedMessage id="footer.message_contact"
                              values={defaultFormatValues}
                            />
                          </span>
                        ),
                        email: (
                          <ContactDropdown type="contactForm">
                            <span className="dropdown-link">
                              <FormattedMessage id="footer.email_contact"
                                values={defaultFormatValues}
                              />
                            </span>
                          </ContactDropdown>
                        ),
                      }}
                    />
                }
                  
                </h3>
              </div>
            </div>
        }
        <div className="row justify-content-between mt-5">
          <div className="col-12 col-lg-auto">
            <img src={logoImg} className="mt-4" height={32} width="auto" alt="Kushki logo" />
            <p className="mt-3 copyright"><small>© 2018-2024<br />Llapingacho LLC.</small></p>
            <LanguageDropdown />
          </div>
          <div className="col-12 col-lg-auto">
            <h6 className="mt-6 mt-lg-4 mb-4">
              <FormattedMessage id="navigation.services"/>
            </h6>

            <div className="row">
              <div className="col-7">
                <ul className={`menu-footer ${'menu-footer--'+theme}`}>
                  <li>
                    <LocalizedLink to="/products">
                      <FormattedMessage id="navigation.payment_processing"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/features/subscriptions">
                      <FormattedMessage id="navigation.subscriptions"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/features/cajita">
                      <FormattedMessage id="navigation.checkout"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/features/link-de-pago-smartlink">
                      <FormattedMessage id="navigation.smartlink"/>
                    </LocalizedLink>
                  </li>

                  <li>
                    <LocalizedLink to="/payments/card">
                      <FormattedMessage id="navigation.cards_short"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/payments/cash">
                      <FormattedMessage id="navigation.cash"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/payments/transfer">
                      <FormattedMessage id="navigation.transfer"/>
                    </LocalizedLink>
                  </li>
                </ul>
              </div>
              <div className="col-3">
                <ul className="menu-footer menu-footer--secondary">
                  <li>
                    <LocalizedLink to="/security">
                      <FormattedMessage id="navigation.security"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/industries/retail" style={{whiteSpace: 'nowrap'}}>
                      <FormattedMessage id="navigation.retail"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/industries/education">
                      <FormattedMessage id="navigation.education"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/industries/tourism">
                      <FormattedMessage id="navigation.tourism"/>
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/industries/services">
                      <FormattedMessage id="navigation.services"/>
                    </LocalizedLink>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div className="col-6 col-lg-auto">
            <h6 className="mt-6 mt-lg-4 mb-4">
              <FormattedMessage id="navigation.developers"/>
            </h6>
            <ul className="menu-footer">
              <li>
                <OutboundLink href="https://docs.kushki.com">
                  <FormattedMessage id="navigation.documentation"/>
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://api-docs.kushkipagos.com/api">
                  <FormattedMessage id="navigation.api_reference"/>
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://status.kushkipagos.com" target="_blank" rel="noreferrer" >
                  <FormattedMessage id="navigation.service_status"/>
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://github.com/kushki" target="_blank" rel="noreferrer" >GitHub</OutboundLink>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-auto">
            <h6 className="mt-6 mt-lg-4 mb-4">
              <FormattedMessage id="navigation.company"/>
            </h6>
            <ul className="menu-footer">
              <li>
                <LocalizedLink to="/aboutUs">
                  <FormattedMessage id="navigation.about_us"/>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/customers">
                  <FormattedMessage id="navigation.customers"/>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/blog">
                  <FormattedMessage id="navigation.blog"/>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/work-with-us">
                  <FormattedMessage id="navigation.work_with_us"/>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/security-policy">
                  <FormattedMessage id="security_policy.title"/>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/operadora">
                  Kushki Operadora S.A.
                </LocalizedLink>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-auto">
            <h6 className="mt-6 mt-lg-4 mb-4">
              <FormattedMessage id="navigation.resources"/>
            </h6>
            <ul className="menu-footer">
              <li>
                <OutboundLink href="https://soporte.kushkipagos.com">
                  <FormattedMessage id="navigation.support"/>
                </OutboundLink>
              </li>
              <li>
                <LocalizedLink to="/legal">
                  <FormattedMessage id="navigation.legal_info"/>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/terms-and-conditions">
                  <FormattedMessage id="navigation.terms"/>
                </LocalizedLink></li>
              <li>
                <LocalizedLink to="/legal">
                  <FormattedMessage id="navigation.privacy"/>
                </LocalizedLink>
              </li>
              {/* <li>
                <LocalizedLink to="/ethicLine">
                  <FormattedMessage id="navigation.ethic_line"/>
                </LocalizedLink>
              </li> */}
              <li>
                <LocalizedLink to="/security-advice">
                  <FormattedMessage id="navigation.security_advice"/>
                </LocalizedLink>
              </li>
              {/* {
                localeCode === 'es' ?
                  <li>
                    <OutboundLink href="https://bit.ly/2SLxW8t">
                      <FormattedMessage id="navigation.media_kit"/>
                    </OutboundLink>
                  </li>
                  : 
                  <li>
                    <OutboundLink href="https://bit.ly/3uDmo4v">
                      <FormattedMessage id="navigation.media_kit"/>
                    </OutboundLink>
                  </li>
              } */}
              {
                localeCode === 'es' ?
                  <li>
                    <OutboundLink href="https://docs.kushki.com/cl/glossary/payment-glossary">
                      <FormattedMessage id="navigation.payment_glossary"/>
                    </OutboundLink>
                  </li>
                  : 
                  <li>
                    <OutboundLink href="https://docs.kushki.com/cl/en/glossary/payment-glossary">
                      <FormattedMessage id="navigation.payment_glossary"/>
                    </OutboundLink>
                  </li>
              }
              
            </ul>
          </div>
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <OutboundLink href="https://github.com/kushki" className="social-button" rel="noreferrer" target="_blank">
              <img src={githubImg} alt="Github" />
            </OutboundLink>
            <OutboundLink href="https://www.instagram.com/kushkioficial" className="social-button" rel="noreferrer" target="_blank">
              <img src={instagramImg} alt="Instagram" />
            </OutboundLink>
            <OutboundLink href="https://www.facebook.com/KushkiOficial" className="social-button" rel="noreferrer" target="_blank">
              <img src={facebookImg} alt="Facebook" />
            </OutboundLink>
            <OutboundLink href="https://twitter.com/kushkioficial" className="social-button" rel="noreferrer" target="_blank">
              <img src={twitterImg} alt="Twitter" />
            </OutboundLink>
            <OutboundLink href="https://www.linkedin.com/company/kushki" className="social-button" rel="noreferrer" target="_blank" >
              <img src={linkedinImg} alt="LinkedIn" />
            </OutboundLink>
          </div>
          <div>
            <img src={ theme ==='primary' ? awsLogoWhite : awsLogo} className="aws-logo-footer"  height="50px" width="auto" alt="PCI DSS logo" />
            <img src={icontech} height="60px" width="auto" alt="PCI DSS logo" />
            <OutboundLink href="https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=kushki" target="_blank" className="footer-icon">
              <img src={pciImg} height="30px" width="auto" alt="PCI DSS logo" />
            </OutboundLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
